import { supportedMarkets } from './supportedMarkets';
import { ASC } from '../constants';

import type { SupportedMarket } from './types';
import type { TableColumn } from '../types';

export const columns: TableColumn[] = [
  { id: 'market-country', title: <h4>Market country</h4>, sortable: true, sortDirection: ASC },
  { id: 'market-name', title: <h4>Market name</h4>, sortable: true },
  { id: 'type', title: <h4>Type</h4> },
  { id: 'code', title: <h4>Code</h4>, sortable: true },
  { id: 'price-delay', title: <h4>Price delay</h4> },
];

const buildTableRow = (supportedMarket: SupportedMarket) => {
  const cells: string[] = Object.values(supportedMarket);

  const { code, marketName } = supportedMarket;

  return { id: `${code}-${marketName}`, cells };
};

export const rows = supportedMarkets.map(buildTableRow);
