import styled, { css } from 'styled-components';

export interface FlexContainerProps {
  direction?: 'row' | 'column';
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around';
  alignItems?: 'stretch' | 'flex-start' | 'flex-end' | 'center' | 'baseline';
  flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  width?: string;
  flex?: string;
}

export const FlexContainer = styled.div<FlexContainerProps>`
  ${({
    direction = 'row',
    justifyContent = 'flex-start',
    alignItems = 'stretch',
    flexWrap = 'nowrap',
    flex,
  }) => css`
    display: flex;
    flex-direction: ${direction};
    justify-content: ${justifyContent};
    align-items: ${alignItems};
    flex-wrap: ${flexWrap};
    height: inherit;
    ${flex &&
    css`
      flex: ${flex};
    `}
  `}
`;
