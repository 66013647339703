import styled, { css } from 'styled-components';

export const StyledTableCell = styled.td<{
  as?: 'th' | 'div';
  width?: number;
  clickable?: boolean;
}>`
  ${({ width }) =>
    width &&
    css`
      width: ${width}%;
    `}

  padding: ${props => props.theme.components.table.rowPadding} 0;

  ${({ as }) =>
    as === 'th' &&
    css`
      padding-bottom: ${props => props.theme.components.table.headerPadding};
    `}

  ${({ clickable }) =>
    !!clickable &&
    css`
      cursor: pointer;
    `}
`;
