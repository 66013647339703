import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

export const StyledSortableTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('lg')} {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
  }

  &::before {
    content: '';
    width: calc(100% - ${getDimension('size6')});
    position: absolute;
    top: 0;
  }

  &,
  & p {
    font-weight: ${props => props.theme.fontWeight.normal};
    font-size: ${props => props.theme.fontSize.xxs};
  }

  // Applying mobile styles to h4 to prevent odd wrapping
  ${({ theme }) => theme.breakpoints.down('lg')} {
    h4 {
      font-weight: ${props => props.theme.fontWeight.bold};
      font-size: ${props => props.theme.fontSize.xs};
      line-height: ${props => props.theme.lineHeight.xs};
    }
  }
`;
