import { useMemo } from 'react';
import { TableCell } from './TableCell';
import { StyledTableRow } from '../styled/StyledTableRow.styled';

import type { FC } from 'react';
import type { TableRowProps } from '../types';

export const TableRow: FC<TableRowProps> = ({ id, cells, shouldRender, columns }) => {
  /**
   * Number of cells and number of columns must be the same!
   */
  if (cells.length !== columns.length) {
    throw new Error(`Row (id: ${id}), mismatching number of cells and columns`);
  }

  const renderCells = useMemo(
    () =>
      cells.map((data, index) => {
        const column = columns[index];

        // Unique key for cell
        const key = `${id}-${column.id}-${data}`;

        return (
          <TableCell key={key} {...column}>
            {data}
          </TableCell>
        );
      }),
    [cells, columns, id]
  );

  /** Row shouldn't be rendered */
  if (shouldRender && !shouldRender(cells)) {
    return null;
  }

  return <StyledTableRow>{renderCells}</StyledTableRow>;
};
