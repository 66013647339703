import { css, keyframes } from 'styled-components';

const moveBackground = keyframes`
  0% { background-position: 0% 0%; }
  25% { background-position: 50% 0%; }
  50% { background-position: 100% 0%; }
  75% { background-position: 50% 0%; }
  100% { background-position: 0% 0%; }
`;

/**
 *  Note: a background style also needs to be provided in the places this is used
 *  e.g. radial-gradient(circle at 50% 50%, #ffd666, #fead81, #f6a7a2, #939ceb);
 */
export const backgroundAnimationMixin = () => css`
  background-size: 400% 100%;
  animation: ${moveBackground} 25s ease infinite;
`;
