import { useCallback, useMemo, useState } from 'react';

import { ASC, DESC } from '../constants';

import type { SortDirection, TableColumn, TableRow } from '../types';

interface UseSortedTableProps {
  rows: TableRow[];
  columns: TableColumn[];
  defaultSortColumn: string;
}

const findColumnIndex = (columnId: string, columns: TableColumn[]) =>
  columns.findIndex(column => column.id === columnId);

const getDefaultColumnSortDirection = (columns: TableColumn[]): { [key: string]: SortDirection } =>
  columns.reduce((acc, column) => ({ ...acc, [column.id]: column.sortDirection }), {});

export const useSortedTable = ({ rows, columns, defaultSortColumn }: UseSortedTableProps) => {
  const defaultColumnSortDirection = getDefaultColumnSortDirection(columns);
  const [selectedColumn, setSelectedColumn] = useState(defaultSortColumn);
  const [columnSortDirection, setColumnSortDirection] = useState(defaultColumnSortDirection);

  const sortedRows = useMemo(() => {
    const columnIndex = findColumnIndex(selectedColumn, columns);
    const ascendingRows = rows.sort((rowA, rowB) => {
      if (!rowA.cells[columnIndex] && !rowB.cells[columnIndex]) {
        return 0;
      }

      if (!!rowA.cells[columnIndex] && !rowB.cells[columnIndex]) {
        return 1;
      }

      if (!rowA.cells[columnIndex] && !!rowB.cells[columnIndex]) {
        return -1;
      }

      // Based on the checks above, we know these exist
      if (rowA.cells[columnIndex]! > rowB.cells[columnIndex]!) return 1;
      if (rowA.cells[columnIndex]! < rowB.cells[columnIndex]!) return -1;
      return 0;
    });

    if (columnSortDirection[selectedColumn] === DESC) {
      return ascendingRows.reverse();
    }

    return ascendingRows;
  }, [selectedColumn, columns, rows, columnSortDirection]);

  const handleOnClick = useCallback((columnKey?: string) => {
    if (!columnKey) throw new Error('No column key was passed to handleOnClick in useSortedTable');

    setSelectedColumn(columnKey);

    setColumnSortDirection(columnDirection => {
      if (columnDirection[columnKey] === ASC) {
        return {
          ...columnDirection,
          [columnKey]: DESC,
        };
      }

      return {
        ...columnDirection,
        [columnKey]: ASC,
      };
    });
  }, []);

  return { sortedRows, handleOnClick, columnSortDirection, selectedColumn };
};
