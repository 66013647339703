import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';

export const TableCellInner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > * {
    margin: 0;
  }
  padding-right: ${getDimension('size8')};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 ${getDimension('size1')};
  }
`;
