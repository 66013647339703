import { useCallback } from 'react';

import { useSortedTable } from './hooks/useSortedTable';
import { TableRow } from './components/TableRow';
import { SortableTableHeaderRow } from './components/SortableTableHeaderRow';
import { StyledSortableTable } from './styled/StyledSortableTable.styled';

import type { FC } from 'react';
import type { SortDirection, TableProps } from './types';

interface SortableTableProps extends TableProps {
  defaultSortColumn: string;
  columnSortDirection?: { [key: string]: SortDirection };
}

export const SortableTable: FC<SortableTableProps> = ({ columns, rows, defaultSortColumn }) => {
  const { sortedRows, handleOnClick, columnSortDirection, selectedColumn } = useSortedTable({
    rows,
    columns,
    defaultSortColumn,
  });

  const renderRows = useCallback(
    () =>
      sortedRows.map(({ id, ...props }) => (
        <TableRow key={id} id={id} columns={columns} {...props} />
      )),
    [columns, sortedRows]
  );

  return (
    <StyledSortableTable>
      <thead>
        <SortableTableHeaderRow
          columns={columns}
          columnSortDirection={columnSortDirection}
          handleOnClick={handleOnClick}
          selectedColumn={selectedColumn}
        />
      </thead>
      <tbody>{renderRows()}</tbody>
    </StyledSortableTable>
  );
};
