import { useMemo } from 'react';

import { StyledTableRow } from '../styled/StyledTableRow.styled';
import { SortableTableCell } from './SortableColumnCell';

import type { FC } from 'react';
import type { SortDirection, TableColumn } from '../types';

interface SortableTableHeaderRowProps {
  columns: TableColumn[];
  handleOnClick: (columnKey: string) => void;
  columnSortDirection?: { [key: string]: SortDirection };
  selectedColumn?: string;
}

export const SortableTableHeaderRow: FC<SortableTableHeaderRowProps> = ({
  columns,
  handleOnClick,
  columnSortDirection,
  selectedColumn,
}) => {
  const renderColumns = useMemo(
    () =>
      columns.map(({ id, title, width, sortable }) => (
        <SortableTableCell
          key={id}
          id={id}
          title={title}
          width={width}
          sortable={sortable}
          handleOnClick={handleOnClick}
          columnSortDirection={columnSortDirection}
          selectedColumn={selectedColumn}
        />
      )),
    [columns, handleOnClick, columnSortDirection, selectedColumn]
  );

  return <StyledTableRow>{renderColumns}</StyledTableRow>;
};
