import styled from 'styled-components';
import { getDimension } from 'styled/helpers/getDimension';
import { theme } from 'styled/theme';

export const TableCellUnderline = styled.div<{
  selected: boolean;
}>`
  width: 100%;
  height: ${getDimension('size1')};
  right: ${getDimension('size6')};

  position: absolute;
  top: ${props => `${props.theme.dimensions.size10}px`};

  background-color: ${props => (props.selected ? theme.colors.orange : theme.colors.cream)};
  transition: all 0.4s ease-in-out;
`;
