import { useCallback } from 'react';

import { StyledTableCell } from '../styled/StyledTableCell.styled';
import { TableCellInner } from '../styled/StyledTableCellInner.styled';

import type { FC } from 'react';
import type { TableCellProps } from '../types';

export const TableCell: FC<TableCellProps> = ({
  children,
  mapData,
  width,
  as,
  handleOnClick,
  columnId,
}) => {
  const renderedData = mapData ? mapData(children) : children;

  const onClick = useCallback(() => {
    if (!handleOnClick || !columnId) return undefined;

    return handleOnClick(columnId);
  }, [columnId, handleOnClick]);

  return (
    <StyledTableCell as={as} width={width} onClick={onClick} clickable={!!handleOnClick}>
      <TableCellInner>{renderedData}</TableCellInner>
    </StyledTableCell>
  );
};
